import React from 'react'
import "../assets/css/style.css"

export default class Error extends React.Component {
    constructor(props){
        super(props);
        this.state = {

        }
    }

    componentDidMount() {

    }

    componentWillUnmount() {

    }

    render() {
        return (
            <div className="page page__404">
                <div className="container d-flex align-items-center">
                    404
                </div>
            </div>
        )
    }
}
